import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { envs } from '@shared/envs/env';
import { SHA256 } from 'crypto-js';

type Valuable<T> = { [K in keyof T as T[K] extends null | undefined ? never : K]: T[K] };

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  private readonly useragent = signal<string>('');
  isMac = signal<boolean>(false);
  isIOS = signal<boolean>(false);
  isSafari = signal<boolean>(false);
  isMobile = signal<boolean>(false);

  router = inject(Router);

  constructor() {
    this.useragent.set(navigator.userAgent.toLowerCase());
    this.isIOS.set(!!this.useragent() && /ipad|iphone|ipod/.test(this.useragent()));
    this.isMac.set(!!this.useragent() && /(mac|iphone|ipod|ipad)/i.test(this.useragent()));
    this.isSafari.set(
      !!this.useragent() && /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    );
    this.isMobile.set(
      !!this.useragent() &&
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge|maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          navigator.userAgent,
        ),
    );
  }

  makeQueryStringFromDictionary(params: any) {
    let output = null;
    if (params && typeof params !== 'undefined') {
      output = '?';
      for (const key of Object.keys(params)) {
        output += key + '=' + params[key] + '&';
      }
    }
    return output;
  }

  hashString(str: string): string {
    return SHA256(str).toString();
  }

  capitalize(value: string): string {
    return value[0].toUpperCase() + value.slice(1);
  }

  static cleanObject<T extends {}, V = Valuable<T>>(obj: T): V {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([, v]) =>
          !((typeof v === 'string' && !v.length) || v === null || typeof v === 'undefined'),
      ),
    ) as V;
  }

  generateMediaLink(path: string): string {
    return `${envs.mediaUrl}/${path}`;
  }
}
